export const environment = {
  production: true,
  host_environment: 'live',
  app_url: 'https://platform.foursource.com',
  public_profile_url: 'https://pro.foursource.com',
  api_url: 'https://prod-public-pages.foursource.com/v1',
  public_gateway_url: 'https://be-gateway-prod.foursource.com/v1/api/p',
  gateway_url: 'https://be-gateway-prod.foursource.com/v1/api',
  redirect_url: 'https://foursource.com/general-page',
  static_content: 'https://cf-prod-static.foursource.com',
  subdomain: '',
  profile_domain: '',
  china: '',
  base_url: 'https://foursource.com',
  internal_url: 'http://public-profile',
  internal_port: '4000',
  gtag: 'GTM-TRDZJ85',
};
