import { environment } from '../environments/environment';

export const CONFIGURATION: any = {
  DEFAULT_LANG: 'en',
  // TRACK_ERROR: true,
  // ENV: environment,
  DOMAINS_API: [
    { HOST: environment.api_url, KEY: '@api' },
    { HOST: environment.static_content, KEY: '@static' },
    { HOST: environment.app_url, KEY: '@local' },
    { HOST: environment.gateway_url, KEY: '@gateway' },
    { HOST: environment.public_gateway_url, KEY: '@public' },
  ],

  ANALYTICS_DOMAINS: [
    {
      domain: 'public-profile-stagingdev10.foursource.pt',
      code: 'UA-90460691-1',
      agency: 'AW-779945857/YLFuCI6T448BEIGP9PMC',
    },
    {
      domain: 'public-profile-staging2.foursource.pt',
      code: 'UA-90460691-1',
      agency: 'AW-779945857/YLFuCI6T448BEIGP9PMC',
    },
    {
      domain: 'public-profile-staging3.foursource.pt',
      code: 'UA-90460691-1',
      agency: 'AW-779945857/YLFuCI6T448BEIGP9PMC',
    },
    {
      domain: 'public-profile-staging4.foursource.pt',
      code: 'UA-90460691-1',
      agency: 'AW-779945857/YLFuCI6T448BEIGP9PMC',
    },
    {
      domain: 'public-profile-staging5.foursource.pt',
      code: 'UA-90460691-1',
      agency: 'AW-779945857/YLFuCI6T448BEIGP9PMC',
    },
    {
      domain: 'public-profile-staging6.foursource.pt',
      code: 'UA-90460691-1',
      agency: 'AW-779945857/YLFuCI6T448BEIGP9PMC',
    },
    {
      domain: 'pro.foursource.com',
      code: 'UA-90460691-2',
      agency: 'AW-779945857/CzmACLutmY0BEIGP9PMC',
    },
  ],

  BASIC: {
    USERNAME: 'foursource',
    PASSWORD: '8Kr8qHgzfcTLpDC5',
    DEFAULT: 'f4s0u45e',
  },

  SECRET_SALT: {
    PASSWORD: 'bqyg3hi3VVU8tWGu',
    DEFAULT: 'f4s0u45e',
  },

  GOOGLE_MAPS_API_KEY: 'AIzaSyDJaCfwCrgzrjIUiOTGgu5GZJw6aepOmzI',

  ENDPOINTS: {
    IMAGES_PATH: '@static:/images/:domain/:section/:size/:path',
    STATICIMAGESDOMAIN: '@static:/images/',
    STATICFILESDOMAIN: '@static:/files/',
    STATICCSVDOMAIN: '@static:/',
    ALIVE: '@api:/alive',
    PROFILE: '@api:/p/entity/website/:uid/site',
    PROFILE_HASH: '@api:/website/hash/:hash_id',
    PROFILE_SECTION: '@api:/p/entity/website/:id/:section',
    PRODUCT_DETAILS: '@api:/p/entity/website/:id/product/:id_product',
    POST_CONTACT: '@public:/entity/website/:id/contact-form',
    TRACKING_ACTION: '@public:/entity/website/tracking/:action',
  },
};
