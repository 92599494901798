import { ModuleWithProviders, NgModule, Optional, PLATFORM_ID, SkipSelf } from '@angular/core';

import { Configurator } from './configurator';
import {
  BreaksPipe,
  ConsolePipe,
  FilterByPipe,
  FilterFieldPipe,
  NoSanitizeHtmlPipe,
  NoSanitizeUrlPipe,
  NumeralPipe,
  SortByPipe,
} from './pipes';
import { CookieService } from './services/cookie.service';
import { CoreSettings, GenericType, OptionsToken, StateToken } from './types';
import { UrlResolver } from './url';

@NgModule({
  exports: [
    BreaksPipe,
    ConsolePipe,
    FilterByPipe,
    NoSanitizeUrlPipe,
    NoSanitizeHtmlPipe,
    NumeralPipe,
    SortByPipe,
    FilterFieldPipe,
  ],
  declarations: [
    BreaksPipe,
    ConsolePipe,
    FilterByPipe,
    NoSanitizeUrlPipe,
    NoSanitizeHtmlPipe,
    NumeralPipe,
    SortByPipe,
    FilterFieldPipe,
  ],
})
export class CommonLibModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CommonLibModule
  ) {
    if (parentModule) {
      throw new Error('CommonModule is already loaded. Import it in the AppModule only');
    }
  }

  public static provides(settings: CoreSettings<GenericType> = {}): ModuleWithProviders<CommonLibModule> {
    return {
      ngModule: CommonLibModule,
      providers: [
        { provide: OptionsToken, useValue: settings.options },
        { provide: StateToken, useValue: settings.state },
        { provide: Configurator, deps: [CookieService, OptionsToken] },
        { provide: UrlResolver, deps: [Configurator, PLATFORM_ID] },
      ],
    };
  }
}

export * from './attribute';
export * from './collection';
export * from './configurator';
export * from './constants';
export * from './exceptions';
export * from './helpers';
export * from './metadata';
export * from './model';
export * from './pipes';
export * from './types';
export * from './url';
