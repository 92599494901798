import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@app/common/constants';
import { UrlResolver } from '@app/common/url';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AnalyticsService } from './common/services/analytics.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(private http: HttpClient, private url: UrlResolver, private analytics: AnalyticsService) {}

  public getAlive(): Observable<any> {
    const endpoint = this.url.get(CONSTANTS.ALIVE);

    return this.http.get(endpoint, { observe: 'response' }).pipe(
      first(),
      map(response => {
        this.analytics.isChina = response.headers.get('ischina');

        return response.headers.get('ischina') || false;
      })
    );
  }
}
