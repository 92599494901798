import { HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Request } from 'express';

@Injectable()
export class CookieService {
  private cookieStore = {};

  constructor(@Optional() @Inject('req') private readonly req: Request) {
    if (this.req !== null) {
      this.parseCookies(this.req.cookies);
    } else {
      const url = window.location.href;
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      this.parseCookies(httpParams.get('preview') === 'true' && document.cookie);
    }
  }

  public get(key: string) {
    return !!this.cookieStore[key] ? JSON.parse(this.cookieStore[key]) : null;
  }

  private parseCookies(cookies) {
    this.cookieStore = {};

    if (!!cookies === false) {
      return;
    }
    const cookiesArr = cookies.split(';');
    for (const cookie of cookiesArr) {
      const cookieArr = cookie.split('=');
      this.cookieStore[cookieArr[0].replace(/\'/g, '').trim()] = cookieArr[1];
    }
  }
}
