export const enum ENUM_DOMAIN {
  MANUFACTURER = 'manufacturer',
  FABRIC = 'fabric',
}

export const CONSTANTS = {
  /* tslint:disable */
  EMAILREGEX:
    "^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-zA-Z0-9!#$%&'*+/=?^_`{|}~.-]+([.][a-zA-Z0-9!#$%&'*+/=?^_`{|}~.-]+)+$",
  PHONEREGEX: '^[0-9+() /-]+$',
  ONLY_LETTERS_REGEX: '^[a-zA-Z]+$',
  ONLY_NUMBERS_REGEX: '^[0-9]+[,0-9]*$',
  PRICE_REGEX: '^[0-9]+[.,0-9]*$',
  ALPHANUMERIC_REGEX: '^[a-zA-Z0-9]+$',
  CITY_REGEX: '^[a-zA-Z]+.*$',
  GPS_REGEX: '^[+|-]?[0-9]+.?[0-9]*,[+|-]?[0-9]+.?[0-9]*$',
  URL_REGEX: '^[a-zA-Z0-9-_/:.]+[.][a-zA-Z0-9-_/.]+$',
  INVALID_CHARS_REGEX: '^[^?!+|:;.<>]+$',
  NAME_REGEX: '^[^?!+|:;/.<>0-9]+$',
  ANTI_SPACE_REGEX: '^(.|\n)*([a-zA-Z0-9]+)(.|\n)*$',
  /* tslint:enable */
  USER: 'user',
  BASIC: {
    USERNAME: 'BASIC.USERNAME',
    PASSWORD: 'BASIC.PASSWORD',
    DEFAULT: 'BASIC.DEFAULT',
  },
  TYPES: {
    MANUFACTURER: 'manufacturer',
    FABRIC: 'fabric',
  },
  DOMAINTYPES: {
    MANUFACTURER: {
      SINGLE: 'manufacturer',
      PLURAL: 'manufacturers',
    },
    FABRIC: {
      SINGLE: 'fabric',
      PLURAL: 'fabrics',
    },
    EXHIBITOR: {
      SINGLE: 'exhibitor',
      PLURAL: 'exhibitors',
    },
  },
  IMAGES: {
    SECTION: {
      FACTORIES: {
        NAME: 'profile-sites-factories',
        CROP: {
          WIDTH: 100,
          HEIGHT: 100,
        },
      },
      BUYER_MINI_PROFILE: {
        NAME: 'miniprofile-cover',
        CROP: {
          WIDTH: 100,
          HEIGHT: 100,
        },
      },
      BUYER_MINI_PROFILE_LOGO: {
        NAME: 'miniprofile-logo',
        CROP: {
          WIDTH: 100,
          HEIGHT: 100,
        },
      },
      POST_FEED: {
        NAME: 'post-feed',
        CROP: {
          WIDTH: 800,
          HEIGHT: 400,
        },
      },
      USER_AVATAR: {
        NAME: 'user-avatar',
        CROP: {
          WIDTH: 150,
          HEIGHT: 150,
        },
      },
      STATIC_MAP: {
        NAME: 'static-map',
      },
    },
  },
  STATICIMAGESDOMAIN: 'STATICIMAGESDOMAIN',
  STATICFILESDOMAIN: 'STATICFILESDOMAIN',
  ENDPOINTS: 'ENDPOINTS',
  ERROR_LOG: 'ENDPOINTS.ERROR_LOG',
  PROFILE: 'ENDPOINTS.PROFILE',
  PROFILE_HASH: 'ENDPOINTS.PROFILE_HASH',
  PROFILE_SECTION: 'ENDPOINTS.PROFILE_SECTION',
  IMAGES_PATH: 'ENDPOINTS.IMAGES_PATH',
  ALIVE: 'ENDPOINTS.ALIVE',
  PRODUCT_DETAILS: 'ENDPOINTS.PRODUCT_DETAILS',
  POST_CONTACT: 'ENDPOINTS.POST_CONTACT',
  TRACKING_ACTION: 'ENDPOINTS.TRACKING_ACTION',
  SECTION: {
    PRODUCTION: "production"
  }

  // tslint:disable-next-line: max-file-line-count
};
