import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from './cookie.service';
import { Observable } from 'rxjs';
import { RestHttpOptions, ResponseSignature } from '../types';


@Injectable()
export class RestService {
  constructor(private http: HttpClient, private cookieService: CookieService) {}

  public get(url: string, options?: RestHttpOptions): Observable<ResponseSignature> {
    return this.http.get<ResponseSignature>(url, {...options, ...this.getHeaders()});
  }

  private getHeaders() {
    const cookie = this.cookieService.get('fs-session');
    let headers = new HttpHeaders();

    if (cookie && cookie.access_token) {
      headers = headers.set('Authorization', 'Bearer ' + cookie.access_token);
    }

    return { headers };
  }

}