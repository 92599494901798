import { Injectable, NgModule } from '@angular/core';
import { CanLoad, RouterModule, Routes } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { AppService } from './app.service';

@Injectable()
export class ProfileGuard implements CanLoad {
  constructor(private service: AppService) {}

  public canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    return this.service.getAlive().pipe(
      first(),
      map(isChina => {
        environment.china = isChina;

        return true;
      })
    );
  }
}

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () => import('@app/containers/profile/profile.module').then(m => m.ProfileModule),
        canLoad: [ProfileGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', initialNavigation: 'enabled' })],
  exports: [RouterModule],
  providers: [ProfileGuard],
})
export class AppRoutingModule {}
