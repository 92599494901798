import { isPlatformServer } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CONFIGURATION } from './app.config';
import { CommonLibModule } from './common';
import { AnalyticsService } from './common/services/analytics.service';
import { CookieService } from './common/services/cookie.service';
import { HandleHttpErrorInterceptor } from './common/services/handle-http-error.service';
import { RestService } from './common/services/rest.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    TransferHttpCacheModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    CommonLibModule.provides({
      options: CONFIGURATION,
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HandleHttpErrorInterceptor, multi: true },
    AnalyticsService,
    CookieService,
    RestService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIconSet(
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `${
          isPlatformServer(this.platformId) ? `${environment.internal_url}:${environment.internal_port}` : ''
        }/assets/icons/fs-icons.svg`
      )
    );

    if (!environment.china) {
      const script: HTMLScriptElement = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${CONFIGURATION.GOOGLE_MAPS_API_KEY}&libraries=places&language=en`;
      script.async = true;
      script.defer = true;
      document.getElementsByTagName('head')[0].appendChild(script);
    }
  }

  // tslint:disable-next-line:max-file-line-count
}
