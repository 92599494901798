import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Response } from 'express';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class HandleHttpErrorInterceptor implements HttpInterceptor {
  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    @Optional() @Inject(RESPONSE) private response: Response
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap({
        error: error => {
          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              case 404:
                if (isPlatformBrowser(this.platformId)) {
                  window.location.href = environment.redirect_url;
                } else {
                  this.response
                    ?.status(404)
                    .send(`<script>window.location.href="${environment.redirect_url}/?redirect=true"</script>`);
                }
                break;
              case 410:
                if (isPlatformBrowser(this.platformId)) {
                  window.location.href = environment.base_url + '/' + error.error.data.redirect;
                } else {
                  this.response
                    ?.status(404)
                    .send(
                      `<script>window.location.href="${environment.base_url}/${error.error?.data?.redirect}/?redirect=true"</script>`
                    );
                }
                break;
              case 302:
                if (isPlatformBrowser(this.platformId)) {
                  window.location.href = environment.base_url + '/' + error.error.data.redirect;
                } else {
                  this.response
                    ?.status(302)
                    .send(
                      `<script>window.location.href="${environment.base_url}/${error.error?.data?.redirect}/?redirect=true"</script>`
                    );
                }
                break;
              default:
                if (isPlatformBrowser(this.platformId)) {
                  window.location.href = environment.redirect_url;
                } else {
                  this.response
                    ?.status(500)
                    .send(`<script>window.location.href="${environment.redirect_url}"</script>`);
                }
                break;
            }
          }
        },
      })
    );
  }
}
