import { Inject, Injectable, isDevMode, PLATFORM_ID } from '@angular/core';
import { find } from 'lodash';
import { Configurator } from '../configurator';
import { extractHostname } from '../helpers';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';

enum USER_TYPE {
  GHOST = 'GHOST',
  USER = 'USER',
}
@Injectable()
export class AnalyticsService {
  public isChina: string;
  private ready = false;
  private domain: { domain: string; code: string; agency: string };
  private cachedEvents: Function[] = [];

  constructor(
    private config: Configurator,
    @Inject(PLATFORM_ID) private _platformId: string,
    @Inject(DOCUMENT) private _dom: Document
  ) {
    this.domain = isPlatformBrowser(this._platformId) && this.checkAnalyticDomain();

    if (this.domain && this.enabled) {
      this.ready = true;
      this.setupTracker(this.domain.code);
    }
  }

  private get enabled(): boolean {
    return !this.isChina && isPlatformBrowser(this._platformId);
  }

  public setupAnalytics() {
    if (this.canUseIt()) {
      this.loadTagManager();
      this.dispatchEvents();
      this.pageView();
    }
  }

  public checkEventTracking(
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null
  ): void {
    if (this.enabled) {
      window.ga('send', 'event', {
        eventCategory,
        eventLabel,
        eventAction,
        eventValue,
      });
    }
  }

  public loadTagManager(): void {
    if (!isDevMode && !this.isChina) {
      const script = document.createElement('script');
      script.text = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${environment.gtag}');`;

      const asyncScript = document.createElement('script');
      asyncScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${environment.gtag}`);
      asyncScript.setAttribute('async', 'async');

      document.head.prepend(asyncScript);
      document.head.prepend(script);
    }
  }

  private setupTracker(trackID: string): void {
    window.ga =
      window.ga ||
      function() {
        (window.ga.q = window.ga.q || []).push(arguments);
      };
    window.ga.l = +new Date();

    window.ga('create', trackID, 'auto', { allowLinker: true });
    window.ga('require', 'linker');
    window.ga('linker:autoLink', ['m.foursource.com', 'app.foursource.com']);
    window.ga('require', 'cleanUrlTracker');
    window.ga('require', 'eventTracker');
    window.ga('require', 'outboundLinkTracker');
    window.ga('require', 'urlChangeTracker');
  }

  private pageView(url: string = null): void {
    if (this.enabled) {
      if (url === null) {
        url = window.location.href + window.location.search;
      }

      window.ga('set', 'page', url);
      window.ga('send', 'pageview');
    }
  }

  private checkAnalyticDomain(): { domain: string; code: string; agency: string } {
    const href = window.location.href;
    const host = extractHostname(href);

    const domains = this.config.getOptionTree<{ domain: string; code: string; agency: string }[]>(
      'ANALYTICS_DOMAINS',
      false
    );

    return find(domains, ['domain', host]) || false;
  }

  private canUseIt(): boolean {
    return this.ready;
  }

  private dispatchEvents(): void {
    this.cachedEvents.forEach((event) => {
      event();
    });
  }
}
