import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
  ActivatedRoute,
  Params,
} from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from './common/services/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public loading = true;

  private destroyer: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private analytics: AnalyticsService,
    private detectionStrategy: ChangeDetectorRef
  ) {}

  public ngOnInit() {
    this.router.events.pipe(takeUntil(this.destroyer)).subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
  }

  public ngOnDestroy(): void {
    this.destroyer.next();
  }

  // Shows and hides the loading spinner during RouterEvent changes
  private navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true;
    }

    if (event instanceof NavigationEnd) {
      this.loading = false;

      this.route.queryParams.subscribe((params: Params) => {
        if (!environment.china && params.preview !== 'true') {
          this.analytics.setupAnalytics();
        }
      });
    }

    if (event instanceof NavigationCancel || event instanceof NavigationError) {
      this.loading = false;
    }

    this.detectionStrategy.markForCheck();
    this.detectionStrategy.detectChanges();
  }
}
